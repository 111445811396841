<template>
  <div style="height:36px">
    <v-btn
        color="orange"

        outlined
        @click="openEventMap()"
    >
      {{ title }}
    </v-btn>

    <v-layout row justify-center>


      <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card>
          <v-toolbar dark color="#FFAB00">
            <v-btn icon dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Оформление заказа {{ event_name }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>

          <div v-if="eventReady" style="font-size: 0.9em">
            <v-stepper v-model="ticketStep">
              <v-stepper-header>
                <v-stepper-step :complete="ticketStep > 1" step="1" :editable="!blockStepForm">Выбор мест
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :complete="ticketStep > 2" step="2">Платежная информация</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step step="3">Перейти к оплате</v-stepper-step>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content step="1">
                  <div style="overflow:auto;">
                    <div class="pay_page">

                      <v-flex xs12 text-xs-center>
                        <v-alert
                            class="footer"
                            v-model="alert"
                            dismissible
                            type="success"
                            icon="priority_high"
                            color="warning"

                        >
                          В данном спектакле билеты на вход, т.е. без возможности выбора места. Схема зала предоставлена
                          в ознакомительных целях
                        </v-alert>
                      </v-flex>

                      <div class="odeum">
                        <div class="odeum_centr" :style="{
                       width: event.layout.width + 'px',
                       }">


                          <div class="odeum_drag" :id="'field_move_'+event.event.id" :ref="'field_move_'+event.event.id" :style="{
                       width: event.layout.width + 'px',
                       background: 'url(https://zakaz.smdtmasterskaya.ru/img/zal/'+event.layout.background+')',
                       height: event.layout.height + 'px' }">


                            <div v-for="seat in event.seats"
                                 :key="seat.id"
                                 v-bind:style="{ top: seat.top + 'px', left: seat.left + 'px' }"
                                 v-bind:id="'seat-' + seat.id"
                                 :class="[{ free: seat.isFree, closed: !seat.isFree}, 'seat z'+ seat.zone, {galerka:galera&&seat.zone==2}]"
                                 v-on:click="seatSelect"
                                 :data-free="seat.isFree"
                                 :data-id="seat.id"
                            >{{ seat.chair }}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="legend_d">

                        <v-container fluid grid-list-xl style="padding-top:8px; text-align:center">
                          <div>
                            <v-chip>
                              <v-avatar class="white" style="border:1px solid gray;">12</v-avatar>
                              Занятое
                            </v-chip>
                            <v-chip>
                              <v-avatar style="background-color:#66ff6d">15</v-avatar>
                              Выбранное вами
                            </v-chip>
                          </div>

                          <div style="padding-bottom:8px;">
                            <v-chip v-for="(zone, index) in event.event.price"
                                    :key="index">
                              <v-avatar
                                  :class="'z'+ (index+1)">
                                {{ index + 1 }}
                              </v-avatar>
                              {{ zone }} руб
                            </v-chip>
                          </div>

                          <div v-if=" galera" style="padding-bottom:8px;">

                            <div style="margin-top: 20px;
                        border: 1px solid black;
                    padding: 0 5px;
                    background-color: #ff4a8d1c;">
                              <v-chip>
                                <v-avatar
                                    class="z2 galerka">2
                                </v-avatar>
                                50 руб
                              </v-chip>
                              Билеты по специальной цене в 50 рублей доступны только при предьявлении студенческого.<br>
                              Подробнее о программе <a target="_blank"
                                                       href="https://samart.ru/content/%D1%82%D0%B5%D0%B0%D1%82%D1%80%D0%B0%D0%BB%D1%8C%D0%BD%D0%B0%D1%8F-%D1%81%D1%80%D0%B5%D0%B4%D0%B0">"Театральная
                              среда"</a>
                            </div>
                          </div>
                          <v-btn
                              :disabled="getTotalSeats==0"
                              @click="ticketStep = 2"
                              color="success"
                              style="height:auto; min-height:40px; padding:7px;"
                          >
                            {{ getTotalPriceString }} <br v-if="getTotalSeats>0"/>Оформить заказ
                          </v-btn>
                        </v-container>
                      </div> <!--legend-->
                    </div>
                  </div>
                </v-stepper-content>

                <v-stepper-content step="2">
                  <v-layout>
                    <v-flex xs12>
                      <div style="font-size:2em; text-align:center;">Вы выбрали</div>
                      <div class="scoop-corners wave">
                        <div class="ticket">
                          <div class="ticket_data">
                            <div class="title_star">&nbsp;&#9733;&#9733;&#9733;&#9733;&#9733;&#9733;&#9733;&nbsp;</div>
                            <div class="t_logo"></div>
                            <div class="t_date">
                              {{ event.event.timedate }}
                            </div>
                            <div class="t_show">
                              {{ event.show.name }}
                            </div>

                            <div class="t_info">
                              <div class="t_count">
                                {{ getTotalSeats }} билетов
                              </div>
                              <div class="t_price">
                                {{ getTotalPrice }} рублей
                              </div>
                            </div>
                            <div class="title_star_bottom">
                              &nbsp;&#9733;&#9733;&#9733;&#9733;&#9733;&#9733;&#9733;&nbsp;
                            </div>
                          </div>

                          <div class="ticket_qr"></div>
                        </div>
                      </div>

                      <div class="formm">
                        <br>
                        <b>Укажите вашу контактную почту</b>
                        <br>
                        (согласно 54-ФЗ с июля 2019 года мы обязаны будем отправлять электронные чеки по всем операциям
                        в интернет магазине вам на почту)
                        <v-form v-model="valid"
                                ref="form_mail">
                          <v-text-field
                              v-model="email"
                              :rules="emailRules"
                              label="E-mail"
                              required
                          ></v-text-field>

                          <v-checkbox
                              v-model="checkbox"
                              color="green"
                              :rules="[v => !!v || 'Обязательно прочтите и подтвердите согласие!']"
                              required
                          >
                            <div slot="label">
                              Ознакомлен и согласен с
                                <a target="_blank" href="#" @click.stop>условиями</a>
                              и
                                <a target="_blank" href="#"
                                   @click.stop>правилами</a>
                              продажи
                            </div>
                          </v-checkbox>
                          <br>
                          После оплаты вы попадете на специальную страничку, где сможете скачать свои билеты.
                          <br><br>
                          <v-btn @click="form_submit" :class="{success: valid, warning: !valid}" style="width:100%;">
                            Перейти к оплате
                          </v-btn>
                        </v-form>
                      </div> <!--formm-->
                    </v-flex>
                  </v-layout>
                </v-stepper-content>

                <v-stepper-content step="3" style="text-align:center; padding: 10px;">
                  <br><br>
                  Регистрируем заказ...<br>
                  <div v-if="oReady">
                    <div v-if="oGood">
                      Успешно, его номер <br>
                      <b>{{ oPayment }}</b>
                      <br><br>
                      Через 5 секунд пойдем в банк
                      <br>
                      <a :href="oBankLink">Перейти</a>
                      <br><br>
                    </div>
                    <div v-else>
                      Что-то пошло не так, печаль.<br>
                      {{ oStatus }}
                    </div>
                  </div>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </div>
          <div v-else class="loader">
            <img src="/img/scanner.gif">
            <br>' +
            Ищем зал...{{ event_id }}
            <br>
          </div>
        </v-card>
      </v-dialog>
    </v-layout>
  </div>

</template>

<script>


export default {
  name: "buy-ticket",

  props: ['event_id', 'title'],

  data () {
    return {
      galera: false,

      event:      [],
      //event_id:   334,
      eventReady: false,
      selSeats:   [],
      order:[],

      dialog: false,

      alert: true,


      valid: false,
      email: "",
      emailRules: [
        v => !!v || 'Поле обязательно для заполнения',
        v => /.+@.+/.test(v) || 'Это не очень похоже на почту'
      ],
      checkbox: false,


      event_name: "",

      ticketStep: 0,
      blockStepForm: false,

      oBankLink:"",
      oStatus:"",
      oPage:"",
      oPayment:"",

      oReady: false,
      oGood: false,

      count: 0

    }
  },

  methods:{

    parsePrice(price_string){

      let t = String(price_string).split('/');
      let r = [];
      //console.log(t);

      t.forEach(elm =>{
        //console.log(elm);
        elm = parseInt(elm);
        //console.log(elm);
        r.push(elm);
      });

      //console.log(t);
      //console.log(r);
      return r;
    },

    form_submit(){

      if (this.$refs.form_mail.validate()) {
        //this.snackbar = true
        console.log('validate_good_ready_redirect_to_bank');

        this.ticketStep = 3;

        this.blockStepForm = true;

        // блокировать кнопки, посылать запрос на регистрацию и если все хорошо - уходить в банк
        this.sendOrder();
      }
    },


    seatSelect: function (event) {

      //console.log(event);

      //let seat = event.toElement;
      let seat = event.target;

      //console.log(seat);

      if (seat.dataset.free=='true'){



        let t = this.event.seats.findIndex(x => x.id === Number(seat.dataset.id));
        //console.log(t);

        if (t>-1){
          this.event.seats[t].selected = !this.event.seats[t].selected;
        }

        let r = this.selSeats.findIndex(x => x === seat.dataset.id);

        // содержит, значит надо удалить
        if (seat.classList.contains('selected')){
          if (r>-1){
            this.selSeats.splice(r,1);
          }
        }
        // добавить
        else{
          if (r==-1){
            this.selSeats.push(seat.dataset.id);
          }
        }

        seat.classList.toggle('selected');
      }

      // место занято
      else{
        //console.log('NOUP');
      }



    },


    // зачем в функцию выделил? забыл
    getSelected: function(){

      this.sendOrder();

    },



    sendOrder:function (){


      let total_price = this.getTotalPrice;

      // это смдт!!!
      let global = {};
      global['key']     = 'KDFJj90ukjdf98df';
      global['format']  = 'raw';
      global['action']  = 'add';
      global['module']  = 'order';


      let base = {};

      base['email']     = this.email;
      base['total']     = total_price;
      base['mode']      = 'inet';


      let orders = [];

      let order = {};
      order['event'] = this.event_id;
      order['price'] = total_price;
      order['seats'] = [];


      // Object.keys(window.selected).forEach(seat_id=>{
      // 	order['seats'].push(window.selected[seat_id].id);
      // });

      order['seats'] = this.selSeats;

      orders.push(order);




      let json = {};
      json['global']          = global;
      json['data']            = {};
      json['data']['base']    = base;
      json['data']['orders']  = orders;

      //console.log(json);

      let jj = JSON.stringify(json);
      console.log(jj);







      let s = '';

      s += 'a=sendorder';

      s += '&event='+this.event_id;
      s += '&seats='+JSON.stringify(this.selSeats);
      s += '&total='+this.getTotalPrice;
      s += '&email='+this.email;

      console.log(s);


      this.oStatus    = "Грузимся";

      ///////////////

      fetch( "https://api.zakaz.cloud/order", {
        method: 'post',
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
        },
        body: jj
      })

          .then(response => response.json())
          .then(json => {

            // не прошли проверки на входные данные
            // пока нет первого заголовка, точнее от всегда 0
            //if (json.status == "1") {

            this.order = json.data;

            if (this.order.data.b == true) {

              this.oGood = true;
              this.oBankLink = this.order.data.bank;
              this.oStatus = "Все успешно";
              this.oPage = this.order.data.page;
              this.oPayment = this.order.data.payment;

              let bank_path = this.oBankLink;

              setInterval(function toobank () {
                console.log( bank_path);
                window.location.href = bank_path;
              }, 5000)

            }
            else {
              this.oGood = false;
              this.oStatus = "Что-то пошло не так, попробуйте еще раз";
            }

            this.oReady = true;
            // }
            //
            // else{
            // 	this.oGood = false;
            // 	this.oStatus = "Попробуйте обновить страницу и повторить";
            // 	this.oReady = true;
            // }
          })

    },



    // информация о мероприятии
    loadEvent: function(){

      console.log('click_button!');

      this.ticketStep    = 0;
      this.eventReady = false;
      this.selSeats = [];
      this.event_name = "";


      // достаем актуальный спектакль
      fetch('https://api.zakaz.cloud/event/'+this.event_id+'/inet?key=KDFJj90ukjdf98df')
          .then(response => response.json())
          .then(json => {
            this.event         = json.data;
            this.ticketStep    = 1;
            this.eventReady = true;
            this.event_name = "- "+ this.event.show.name;

            this.event.event.price = this.parsePrice(this.event.event.prices);


            this.event.seats.forEach(seat=>{
              seat.selected = false;
            })



            this.alert = Boolean(this.event.price_map.only_entry);


          })

    },

    openEventMap: function(){

      //console.log(event_id);
      this.dialog = true;
      //this.event_id = event_id;

      this.loadEvent();



      console.log('open_event');
    },





  },


  computed:{

    getTotalSeats(){
      return (this.selSeats.length);
    },

    getTotalPrice(){



      console.log('!');

      let y = this.event.seats.filter( x => x.selected === true);

      //console.log('1');

      console.log(y);



      let thiss = this;

      let summ = 0;
      summ = this.selSeats.length;

      y.forEach( function(seat){
        //console.log(seat.zone);
        summ += thiss.event.event.price[seat.zone-1];
      });

      summ -= this.selSeats.length;

      return summ;
    },

    getTotalPriceString(){

      let s = "";

      if (this.getTotalSeats==0){
        s = "";
      }
      else{
        if (this.getTotalSeats==1){s = "1 билет, ";}
        else { s = this.getTotalSeats+" билета, ";}

        if (this.getTotalSeats>4){s = this.getTotalSeats+" билетов, ";}

        s+=this.getTotalPrice+" рублей";
      }

      return s;

    },







  },

  created(){

    console.log("mmm_component");
    //this.loadAfisha();
    //this.openEventMap(this.event_id);
    //this.openEventMap();


  },


}





</script>

<style scoped>



.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  width: 100%;
}

.container.grid-list-md .layout .flex{
  padding:16px;
}

/*.v-card__text{*/
/*min-height:251px;*/
/*}*/


.afisha_table{
  width:100%;
  color:black;
  /*border-spacing: 0 15px;*/
  border-spacing: 0;
}

.afisha_table tr {
  transition: 0.6s;
  cursor:pointer;
}

.afisha_table tr:hover {

  box-shadow:0px 0px 5px black;
  background-color: rgba(255, 221, 134, 0.53);
}

.afisha_table tr:hover .show_name {

  /*color:white;*/
  text-shadow:1px 1px #737373;

}

.afisha_table tr:hover .day_cont {
  transform: scale(1.1);
}

.afisha_table tr:hover td {

}


tr.border_bottom td {
  border-bottom: 1px solid #4a0000;
  box-shadow: 0px 1px 0px 0px #8a8a8a;
}


.afisha_table td{
  padding:7px;
}

.show_name{
  font-size:1.6em;
  /*color:#00460c;*/
  color:black;
  text-shadow:1px 1px #cccccc;
}

a .show_name{
  color:red;
}

.day_cont{
  max-width:81px;
  width:80px;
  margin: 0 auto;
  transition:0.6s;
}


.day_n{
  margin:5px;
  font-size:2em;
  border:1px solid white;
  background-color: rgba(255, 255, 255, 0.85);
  min-width:50px;

  box-shadow:1px 1px 3px 1px #000;
  transition:0.6s;
}

.day_month{
  font-size:0.5em;
  /*background-color:#80beff;*/
  background-color: #ffe266;
  border: 1px solid #964242;

}

.day_time{

  padding:5px 0;
  font-size:0.8em;
  /*background-color:gray;*/
  font-weight:700;

}

.age_strict{
  border-radius:50px;
  border:2px solid black;
  width:40px;
  height:40px;
  font-size:1.1em;
  padding-top:5px;
  /*margin-top:-7px;*/
  margin:-7px auto 12px;
}

.event_free{
  font-size:0.8em;
  font-weight: 700;
  color:#3e0000;
}

.namee, .namee2{

  /*font-family: 'Forum', cursive;*/
  /*font-family: 'Cormorant', serif;*/
  /*font-family: 'Lora', serif;*/
  /*font-family: 'PT Serif Caption', serif;*/
  /*font-family: 'Spectral', serif;*/

  /*font-family: 'Forum', cursive;*/

  /*font-family: 'Cormorant', serif;*/
  /*font-family: 'Lora', serif;*/
  /*font-family: 'Alice', serif;*/
  /*font-family: 'PT Serif Caption', serif;*/
  font-family: 'Spectral', serif;

  font-size:3em;

}

.namee2{
  text-transform: uppercase;
  font-size:3.4em;
  /*color:#F09F6A;*/
  /*color: #ffd94d;*/
  /*color:#E34C38;*/
  /*color:#ffcd17;*/
  /*color:rgb(243, 204, 62);*/
  /*text-shadow:0px 2px 4px black, 0px 0px 6px #ff660d;*/
  position:relative;
  /*width:900px;*/
  margin:16px auto;
  /*text-shadow: 1px 1px 2px white, -1px -1px 2px white;*/

  color: rgb(255, 200, 0);
  text-shadow: 1px 1px 0px #000c;

}


.namee3{


  font-family: 'Alice', serif;

  font-size:2em;
  /*color:#F09F6A;*/
  color:#ecad1f;
  text-shadow:0px 2px 4px black;

  border:2px dashed white;
  /*border-radius:  60px 45px 30px 15px/15px 30px 45px 60px;*/
  border-radius:  60px 60px 15px 15px/15px 15px 60px 60px;
  width:300px;
  margin:0 10px;
  background-color:rgba(14, 49, 0, 0.96);
  transition: 0.3s;

}

.namee3:hover{
  background-color:rgba(38, 95, 15, 0.56);
}


.alll
{

  /*padding-top:50px;*/

  background:

      linear-gradient(to right, rgba(45, 45, 45, 0.9), rgb(33, 33, 33)), url(/des/png/24.png), #012b10;

  /*linear-gradient(to right, rgba(0, 68, 28, 0.94),*/
  /*rgba(0, 43, 20, 0.97)),*/
  /*url(/des/png/24.png),*/
  /*#012b10;*/

  /*!*background-image:url(/des/png/01.png);*!*/

  /*background: linear-gradient(to right, rgba(40, 41, 53, 0.41),   rgba(29, 30, 33, 0.44)),   url(/des/png/20.png),   #012b10;*/

  /*background: linear-gradient(to right, rgba(106, 126, 173, 0.48), rgba(7, 47, 108, 0.4)), url(/des/png/20b4.png), #012b10;*/

  /*background: linear-gradient(to right, rgba(40, 41, 53, 0.41), rgba(29, 30, 33, 0.44)), url(/des/png/20.png), #012b10;*/

  overflow: hidden;

}


.logo{
  background: url('/img/fasad_s.jpg') -10px -5px;
  border:1px solid black;
  width: 86px;
  height:86px;
  border-radius:44px;
  cursor:pointer;
  transition: 0.3s;
  float:left;
  margin-left:10px;
  margin-top:5px;
  position:absolute;
}

.logo:hover{
  /*background: url('/img/fasad_color_s.jpg') -10px;*/
  border-radius:10px;
  filter: sepia(100%);
  /*border:1px dashed #ffbd19;*/
}

.logo2{
  background: url('/img/logo_theatre_spl.png');
  width:553px;
  height:113px;

  margin:10px auto;
}


.div1{
  background: url('/img/div_1.png') center;
  /*width:553px;*/
  /*height:113px;*/
  color:#F09F6A;

  /*margin:10px auto;*/
}


.top_menu{
  /*border-bottom:2px dashed black;*/
  position:relative;
  /*background-color:white;*/
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

}

/*.top_menu:before {*/
/*position:absolute;*/
/*top:0;*/
/*left:-100px;*/
/*width:200px;*/
/*height:100%;*/
/*!*background-color:green;*!*/
/*content:"";*/
/*border-bottom:2px dashed black;*/
/*}*/

.top_menu:before {
  position:absolute;
  top:-20%;
  left:-20%;
  width:140%;
  height:120%;
  /*background-color:blue;*/
  content:"";
  border-bottom:2px dashed #5e5f19;
  background-color:rgba(0, 0, 0, 0.21);

}


.yaerr{
  /*background-color:#0f4013;*/
  border:1px solid white;
  box-shadow: inset 0px 0px 0px 5px #4a2f00, inset 0px 0px 0px 6px white, 1px 1px 10px 2px rgba(153, 153, 153, 0.8);


  /*background:*/
  /*	repeating-linear-gradient( -45deg,*/
  /*	rgba(255, 236, 254, 0) 1.8em,*/
  /*	rgba(150, 0, 16, 0.16) 2em,*/
  /*	rgba(197, 194, 191, 0) 2.1em )*/
  /*	0 0;*/

}


.monthh{
  border:1px dashed #792121;
  /*background-color:#ffeebf;*/
  /*background-color: #88ff90;*/

}

.monthh_name{
  /*border:1px solid white;*/
  padding:10px;
  text-align:center;
  border-bottom: 1px dashed #792121;
  background: radial-gradient(#e0ae57, #ce8a00), url("/img/bubles.jpg"), #2b0101;

  font-family: 'Alice', serif;

  font-size:1.8em;
  /*color:#F09F6A;*/
  color:#fff;
  text-shadow:0px 2px 4px black;


  /*background-color:rgba(14, 49, 0, 0.96);*/
  transition: 0.3s;


}

.monthh_data{
  /*border:1px solid green;*/
  background-color:rgb(242, 242, 242);

  /*//background: linear-gradient(to right, rgba(255, 255, 255, 0.45), rgba(255, 255, 255, 0.8)), url("/des/fonn2.jpg"), #012b10;*/

  /*font-family: 'Forum', cursive;*/
  /*font-family: 'Spectral', serif;*/
  /*font-family: 'Cormorant', serif;*/
  /*font-family: 'Lora', serif;*/
  /*font-family: 'Alice', serif;*/
  /*font-family: 'PT Serif Caption', serif;*/

  font-family: 'Forum', cursive;
  font-size:1.2em;

}






.pay_page{

  border:1px solid #505050;
  width:100%;
  height:100%;
  margin: 0 auto;
  border-radius:20px;
  background-color:#fff7e5;
  overflow: hidden;

}

.legend_d{
  /*min-width:797px;*/
  min-width:107px;
  margin:0 auto;

  background-color: #ffe9cc;


  /*border:1px solid green;*/
  /*background-color:white;*/

}



.odeum{

  /*border:1px solid green;*/
  border-bottom:2px dashed black;
  min-width:500px;
  min-height:400px;

  overflow: scroll;

  position:relative;
  margin:3px auto 3px;
  background: url("/img/zal/zal.png");
}

.odeum_centr{
  margin: 0 auto;
  position:relative;
  height:500px;
}

.odeum_drag{


  /*border:1px solid red;*/
  min-width:500px;
  min-height:330px;



  position:relative;

  user-select: none;

  /*cursor:move;*/

}




.seat{
  position:absolute;
  background-color:blue;

  width:24px;
  height:24px;

  cursor:pointer;
  user-select: none;

  text-align:center;
  color:white;
  margin:10px;
  border-radius:2px;
  font-size:12px;
  color: black;
}


.seat.free{
  /*background-color:yellow;*/
  /*color:black;*/
}

.seat.free.selected{
  /*background-color:green;*/
  /*color:black;*/
  border:1px solid black;
  background-color:#66ff6d;
  color:black;
}



.z1 {
  background-color: #ffe863;
}

.z2 {
  background-color: #ffad2d;
}

.z3 {
  background-color: #fb694d;
  color:white;
}

.z4 {
  background-color: #ff99f3;
}

.z5 {
  background-color: #f9406d;
  color:white;
}

.galerka{
  background-color: #3d3dff;
}


.closed{
  /*background-color:#9896fc;*/
  background-color:white;
  border:1px solid #656565;
  cursor:not-allowed;
  color:#cecece;
}

.closed:hover{

  background-color:red;

}


.theme--light.v-chip{
  /*background-color:#fff3c7;*/
  background-color:#ffdfa4;

}

.theme--light.v-chip:not{
  /*background-color:#fff3c7;*/
  background-color:#ffdfa4;

}

.theme--light.v-chip:not(.v-chip--active){
  background-color:#ffdfa4;
}




.ticket{
  display: flex;
  align-items: stretch;
  width:486px;
  height:200px;
  margin:0 auto;
  background-color:#ffddb0;

}

.ticket_data{
  width:396px;
  border:4px solid #ffddb0;
  outline: solid 6px #ff7e4e;
  flex-grow:1;
  background-color: #ff7e4e;
  padding:15px 20px;
  margin:15px;
}

.title_star, .title_star_bottom{
  position:absolute;
  background-color:#ff7e4e;
  color:#ffddb0;
  top:10px;
  width:190px;
  margin:0 auto;
  left:138px;
  font-size:1em;
  letter-spacing: 12px;
  text-align:center;
}
.title_star_bottom{
  top:auto;
  bottom:10px;

}

.ticket_qr{
  width:90px;

  /*border:1px solid purple;*/
  background: url('https://zakaz.smdtmasterskaya.ru/img/barcode_150.png') 12px center;
  flex-grow:1;
  background-color: #fff3de;
  /*background-size: contain;*/
  background-repeat: no-repeat;

}


.t_show{
  font-size:1.8em;
  height:75px;
  text-align:center;
}

.t_date{
  text-align:center;
}

.t_info{

  text-align:center;
  font-size:1em;
  /*padding-left: 90px;*/

}

.t_logo{

  width:150px;
  height:140px;
  background: url('/img/repka_ticket.png') center;
  flex-grow:1;
  /*background-color: #fff3de;*/
  background-size: contain;
  background-repeat: no-repeat;
  position:absolute;
  bottom:25px;
  left:16px;
}



.scoop-corners {

  width: 550px;
  height:200px;
  background:
      radial-gradient(circle at top left,     transparent 25px, #ffddb0 26px) top left,
      radial-gradient(circle at top right,    transparent 25px, #fff3de 26px) top right,
      radial-gradient(circle at bottom right, transparent 25px, #fff3de 26px) bottom right,
      radial-gradient(circle at bottom left,  transparent 25px, #ffddb0 26px) bottom left;

  background-size: 50% 50%;
  background-repeat: no-repeat;

  margin: 30px auto;

  /*border:1px solid green;*/
}



.wave{
  /*margin: 10px auto;*/
  /*height: 150px;*/
  /*width:200px;*/
  position: relative;
  /*border:1px solid orange;*/
  /*background-color: #d0d2f0;*/
}

.wave::before{
  content: "";
  position: absolute;
  left: 0;
  /*bottom: 0;*/
  right: 0;
  background-repeat: repeat;
  width: 15px;
  height:120px;
  background-size: 30px 42px;
  background-image:
      radial-gradient(circle at 0px 15px, white 12px, #ffddb0 13px);
  margin-top:42px;
}

.wave::after{
  content: "";
  position: absolute;
  left: auto;
  top: 0;
  right: 0;
  background-repeat: repeat;
  width: 15px;
  height:120px;
  background-size: 30px 42px;
  background-image:
      radial-gradient(circle at 15px 15px, white 12px, #fff3de 13px);
  margin-top:42px;

}


.lk_txt{
  margin-top:-26px;
  margin-left:10px;

  font-family: 'Alice', serif;
  font-size: 1.4em;

  color: #ecad1f;
  text-shadow: 0px 2px 4px black;
  transition: 0.3s;
  cursor: pointer;

  text-align: left;
}

.lk_txt:hover{
  color:#f3c96a;
}

.lk_in{
  /*border-top: 29px solid #033300;*/
  border-top: 29px solid #840000;
  border-left: 29px solid transparent;
  width:208px;
  height:29px;

  margin-top:-38px;
  margin-left:-36px;

  transition: 0.3s;
}

.lk_in:hover{
  /*border-top: 29px solid #9e611e;*/
  border-top: 29px solid #9e0020;

}


.lk{
  position: fixed;
  background-color: transparent;
  padding: 8px;
  /*border: 2px dashed black;*/
  right: 0;
  top: 64px;
  transition: 0.2s;
  z-index: 90;
  /*border-radius: 8px;*/
  text-shadow: 1px 1px 1px #b2ffad;

  /*font-size: 0px; line-height: 0%; width: 100px;*/
  border-top: 30px solid #d4a10f;
  border-left: 30px solid transparent;

  width:210px;
  height:46px;
}



.formm{
  max-width:550px;
  min-height:400px;
  border:2px dotted #ffbb77;
  margin: 0 auto;
  padding:20px;
}


.loader{
  margin:200px auto;
  width:200px;
  height:200px;
  text-align:center;
}

.footer {
  width: 100%;
  /*height: 50px;			*/
  /*position: absolute;*/
  top: 0px; left: 0;
  margin-bottom:10px;
}


.spinner {
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative;
  text-align: center;

  -webkit-animation: sk-rotate 2.0s infinite linear;
  animation: sk-rotate 2.0s infinite linear;
}

.dot1, .dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: #333;
  border-radius: 100%;

  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.dot2 {
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-rotate { 100% { -webkit-transform: rotate(360deg) }}
@keyframes sk-rotate { 100% { transform: rotate(360deg); -webkit-transform: rotate(360deg) }}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
}

.preload{
  text-align:center;
}

.tour:before {
  position: absolute;
  right: -17px; /*горизонтальное смещение*/
  bottom: -27px; /*вертикальное смещение*/
  border-width: 15px;
  border-style: solid;
  border-color: #f7a958 #f7a958 #f7a958 transparent;
  box-shadow: 10px 7px 15px -5px #4e4e4e;
  font: normal normal 16px/1px Tahoma, Arial, sans-serif;
  color: #fff;
  text-indent: 15px;
  text-shadow: 1px 1px 1px #000;
  height: 0;
  min-width: 60px;
  max-width: 140px;
}
.tour:after {
  position: absolute;
  content:'';
  right: -17px; /*горизонтальное смещение*/
  bottom: -37px; /*вертикальное смещение, меньше на собственную высоту*/
  border-width: 5px;
  border-style: solid;
  border-color: #694622 transparent transparent #694622;
  height: 0;
}
.tour:before {
  content:'Гастроли';
}

.tour{
  position:relative;
}


.dayy{

  background-color: rgba(255, 155, 4, 0.53);

}

.show_ready{
  background-color:white;
}



.premiere:before {
  position: absolute;
  right: -17px; /*горизонтальное смещение*/
  bottom: -27px; /*вертикальное смещение*/
  border-width: 15px;
  border-style: solid;
  border-color: #6b2626 #6b2626 #6b2626 transparent;
  box-shadow: 10px 7px 15px -5px #4e4e4e;
  font: normal normal 16px/1px Tahoma, Arial, sans-serif;
  color: #fff;
  text-indent: 15px;
  text-shadow: 1px 1px 1px #000;
  height: 0;
  min-width: 60px;
  max-width: 140px;
}
.premiere:after {
  position: absolute;
  content:'';
  right: -17px; /*горизонтальное смещение*/
  bottom: -37px; /*вертикальное смещение, меньше на собственную высоту*/
  border-width: 5px;
  border-style: solid;
  border-color: #4c1616 transparent transparent #164c1a;
  height: 0;
}
.premiere:before {
  content:'Премьера';
}

.premiere{
  position:relative;
}








.nav_menu_c{
  /*border:1px solid red;*/
  /*background-color: rgba(0, 23, 7, 0.93);*/
  background-color: #1f1f23;

  box-shadow: 1px 1px 1px #ecad1f,
  1px 5px 25px black;

  padding:0;
  margin-top:-50px;

  border-bottom:1px solid #c29600;

  position: fixed;
  width:100%;
  z-index: 100;

  overflow: hidden;

}



.nav_menu_p{

}

.nav_name{

  font-family: 'Alice', serif;
  font-size: 1.4em;
  /* color: #F09F6A; */
  color: #ecad1f;
  text-shadow: 0px 2px 4px black;
  cursor:pointer;
  margin:8px 0 0 20px;

}

.nav_el{
  /*border:1px solid green;*/

  font-family: 'Alice', serif;
  font-size: 1.4em;
  /* color: #F09F6A; */
  color: #ecad1f;
  text-shadow: 0px 2px 4px black;


  transition: 0.3s;
  cursor:pointer;

  position:relative;

  border:1px solid transparent;



}

.nav_el::before{

  content: "";
  position: absolute;
  left: -4px;
  top: 30px;
  right: auto;
  background-repeat: repeat;
  width: 10px;
  height: 9px;
  background-size: 12px 12px;
  background-image: radial-gradient(circle at 3px 3px, #d4a10f 1px, rgba(255, 221, 176, 0) 3px);


}

.nav_el::after{
  content: "";
  position: absolute;
  left: auto;
  top: 28px;
  right: -13px;
  background-repeat: repeat;
  width: 17px;
  height: 17px;
  background-size: 20px 19px;
  background-image: radial-gradient(circle at 5px 5px, #ffd10b 3px, rgba(255, 221, 176, 0) 5px);

}

.nav_el:first-child::before,
.nav_el:last-child::after{
  background-repeat: repeat;
  top: 29px;
  width: 10px;
  height: 9px;
  background-size: 12px 12px;
  background-image: radial-gradient(circle at 3px 3px, #d4a10f 1px, rgba(255, 221, 176, 0) 3px);
}

.nav_el:hover{

  /*background-color: rgba(245, 192, 0, 0.22);*/
  /*background-image:linear-gradient(to bottom right, red, yellow);*/
  background-image:linear-gradient(rgb(78,4,4) ,  rgba(0, 0, 0, 0.85) );
  border-left:1px solid #ffd10b;
  border-right:1px solid #ffd10b;

  text-shadow: 0px 2px 4px #49500d;
  color: #efff00;

}



.header_top{

  /*border-bottom: 2px dashed #5e5f19;*/
  background-color: rgba(0, 0, 0, 0.21);
  transition: 0.6s;
  cursor: pointer;
  /* background-color: #16132d; */
  /* background-color: #2a292f; */
  /* background-color: #4e0404; */
  background: rgb(34, 51, 78);
  /*background: radial-gradient(circle, rgba(78,4,4,1) 0%, rgba(194,35,35,1) 22%, rgba(255,255,255,0) 100%);*/
  /*background: radial-gradient(circle, rgb(62, 62, 70) 0%, rgb(0, 0, 0) 32%, rgba(255,255,255,0) 100%);*/

  /*background: url(/des/png/27b2.png), rgb(78,4,4);*/

  /*background: radial-gradient(circle, rgba(84, 0, 0, 0.58) 0%, rgba(68, 0, 0, 0.33) 8%, rgba(0, 0, 0, 0.85) 100%), url(/des/png/27b2.png), #012b10;*/

  /*background: radial-gradient(circle, rgba(130, 0, 0, 0.46) 0%, rgba(97, 0, 0, 0.21) 62%, rgba(0, 0, 0, 0.41) 100%), url(/des/png/27b2.png), #012b10;*/

}

.header_top:hover{
  /*border-bottom:2px dashed #d4000f;*/
  /*background-color: rgba(60, 18, 125, 0.48);*/

  /*border-bottom: 2px dashed #ffffff;*/
  /*background-color: rgba(128, 1, 1, 0.48);*/
  /*background-color: rgba(49, 7, 42, 0.61);*/



}


.slider_top{
  /*border:1px solid yellow;*/
  /*background:repeating-linear-gradient( -45deg, rgba(255, 236, 254, 0) 1.8em, rgba(59, 61, 70, 0.16) 2em, rgba(197, 194, 191, 0) 2.1em ) 0 0;*/
  border-bottom: 2px dashed #5e5f19;

  /*background:url(/des/png/21.png) repeat;*/

  border-bottom: 2px dashed #5e5f19;

  background: repeating-linear-gradient( -45deg, rgba(255, 236, 254, 0) 1.8em, rgba(62, 0, 0, 0.16) 2em, rgba(197, 194, 191, 0) 2.1em ) 0 0;
  background-color: #131315;

}

.small_afisha{
  /*border:1px solid purple;*/
}

.news{
  /*border:1px solid deepskyblue;*/
}

.all_afisha_block{

  background: repeating-linear-gradient( -45deg, rgba(255, 236, 254, 0) 1.8em, rgba(146, 43, 43, 0.16) 2em, rgba(197, 194, 191, 0) 2.1em ) 0 0,
  linear-gradient(to right, rgba(0, 0, 0, 0.39), rgba(0, 0, 0, 0.24)),
  url(/des/png/26.png), #012b10;

  background: radial-gradient(circle, rgba(130, 0, 0, 0.46) 0%, rgba(97, 0, 0, 0.21) 62%, rgba(0, 0, 0, 0.41) 100%), url(/des/png/27b2.png), #012b10;

  /*background: radial-gradient(circle, rgba(130, 0, 0, 0.65) 0%, rgba(97, 0, 0, 0.53) 62%, rgba(0, 0, 0, 0.71) 100%), url(/des/png/27b2.png), #012b10;*/

  /* background-color: white; */
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  transition: 0.3s;
  z-index: 1;
  position: relative;
  cursor: pointer;
}

.all_afisha_block:after{

  background: repeating-linear-gradient( -45deg, rgba(255, 236, 254, 0) 1.8em, rgba(146, 43, 43, 0.16) 2em, rgba(197, 194, 191, 0) 2.1em ) 0 0,
  linear-gradient(to right, rgba(0, 0, 0, 0.39), rgba(0, 0, 0, 0.24)),
  url(/des/png/27.png), #012b10;

  background:
      linear-gradient(to right, rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0.04)),
      url(/des/png/26.png), #012b10;

  background: radial-gradient(circle, rgba(130, 0, 0, 0.46) 0%, rgba(97, 0, 0, 0.21) 62%, rgba(0, 0, 0, 0.41) 100%), url(/des/png/27b2.png), #012b10;

  background: radial-gradient(circle, rgba(84, 0, 0, 0.58) 0%, rgba(68, 0, 0, 0.33) 8%, rgba(0, 0, 0, 0.85) 100%), url(/des/png/27b2.png), #012b10;

  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  position: absolute;

  transition: opacity 0.5s ease-out;
  z-index: 2;
  opacity: 0;

}

.all_afisha_block:hover:after {
  opacity: 1;
}

.all_afisha_block:hover{
  border-top: 1px solid #ffc107;
  border-bottom: 1px solid #ffc107;
}




.fooot{
  /*border:1px solid #db77ff;*/

  background-color: rgba(71, 0, 0, 0.7);
  /*box-shadow: -1px -1px 1px #0c3300, -1px -1px 10px black;*/

  padding:0;

  /*border-top:1px solid #0a7100;*/
  color:white;

  box-shadow: -1px -1px 1px #ecad1f, -1px -1px 10px black;
  border-top: 1px solid #c29600;

  /*width:100%;*/



}






.swiper-container {
  /*height: 100%;*/
  width:  100%;
}

.swiper-slide {
  text-align: center;
  font-size: 38px;
  font-weight: 700;
  background-color: transparent;
  /*background-color: red;*/
  background-image:url('/data/show/15/big/10.jpg');
  background-size: contain;
  background-position:center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  /*align-items: center;*/
  color:white;
  height:330px;
}






.box {
  /*position: absolute;*/
  /*top: 50%;*/
  /*left: 50%;*/
  /*transform: translate3d(-50%, -50%, 0);*/
  background-color: rgba(0, 0, 0, 0.5);
  /*width: 100%;*/

  padding: 5px;
  border: 2px solid #b78846;
}
.box:before, .box:after {
  content: "•";
  position: absolute;
  width: 14px;
  height: 14px;
  font-size: 14px;
  color: #b78846;
  border: 2px solid #b78846;
  line-height: 12px;
  top: 5px;
  text-align: center;
  z-index:50;
}
.box:before {
  left: 23px;
  top:7px;
}
.box:after {
  right: 23px;
  top:7px;
}
.box .box-inner {
  position: relative;
  border: 2px solid #b78846;
  padding: 5px;
  background: repeating-linear-gradient( -45deg, rgba(255, 236, 254, 0) 1.8em, rgba(150, 36, 36, 0.16) 2em, rgba(197, 194, 191, 0) 2.1em ) 0 0;
}

.box .box-inner:before, .box .box-inner:after {
  content: "•";
  position: absolute;
  width: 14px;
  height: 14px;
  font-size: 14px;
  color: #b78846;
  border: 2px solid #b78846;
  line-height: 12px;
  bottom: -2px;
  text-align: center;
  z-index:50;
}
.box .box-inner:before {
  left: -2px;
}
.box .box-inner:after {
  right: -2px;
}


.event_small, .partner-slide{
  font-size:1em;
  background-color: rgba(65, 66, 84, 0.27);
  min-width: 250px;
  height: 250px;
  background-size: cover;
  border:1px solid #5a0000;
  border:1px solid #b78846;

  box-sizing:border-box;

  position:relative;
  transition: 0.3s;
  cursor:pointer;
}

.event_small:hover{
  border:1px solid #b78846;
  border:1px solid #5a0000;
}

.event_small:hover .event_sq_top,
.event_small:hover .event_sq_bottom{
  border-color: #b78846;
  background: rgba(122, 81, 0, 0.8);
}



.event_sq_top{
  position:absolute;
  top:0;
  width:100%;
  /*height:30px;*/
  /*border-bottom:1px solid #b78846;*/
  /*background: rgba(122, 81, 0, 0.8);*/
  border-bottom:1px solid #407d00;
  background: rgba(19, 83, 0, 0.8);
  line-height: 2.5em;
  font-size:1.2em;
  color:white;
  text-shadow: 1px 1px 2px black;


}

.event_sq_bottom{
  position:absolute;
  bottom:0;
  width:100%;
  /*height:30px;*/
  border-top:1px solid #407d00;
  background: rgba(19, 83, 0, 0.8);
  line-height: 2.5em;
  font-size:1.2em;
  color:white;
  text-shadow: 1px 1px 2px black;
}

.event_sq_picture{
  position:absolute;
  bottom:0;
  width:100%;
  /*height:30px;*/
  border:1px solid blue;
  background: rgba(0, 0, 0, 0.83);
}


.footer_el{
  /*border:1px solid green;*/
}

.news_body{
  /*border:1px solid green;*/
  margin: 10px 0;
  /*background-color: rgba(255, 255, 255, 0.84);*/

  background: url("/des/jpg/01.jpg"), rgba(255, 255, 255, 0.84);
}

.logo20{
  background: url('/img/fasad_150.jpg') 17px -5px;
  background-repeat: no-repeat;
  opacity: 0.5;
}


.news_img_c img{
  width:100%;
  max-width:500px;
}


.partner-slide{

  background-size: contain;
  overflow: visible;
  border:0;
  background-color:transparent;
}

.partner-name{
  /*border:1px solid red;*/
  position:absolute;
  bottom:-40px;
  width:100%;
  height:30px;

  font-family: 'Alice', serif;
  font-size: 1.4em;
  color: #ecad1f;
  text-shadow: 0px 2px 4px black;
}








/* Alignment styles for images, videos and iframes in editable regions */

/* Center (default) */
[data-editable] iframe,
[data-editable] image,
[data-editable] [data-ce-tag=img],
[data-editable] img,
[data-editable] video {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}

/* Left align */
[data-editable] .align-left {
  clear: initial;
  float: left;
  margin-right: 0.5em;
}

/* Right align */
[data-editable].align-right {
  clear: initial;
  float: right;
  margin-left: 0.5em;
}

/* Alignment styles for text in editable regions */
[data-editable] .text-center {
  text-align: center;
}

[data-editable] .text-left {
  text-align: left;
}

[data-editable] .text-right {
  text-align: right;
}

.author {
  font-style: italic;
  font-weight: bold;
}


.bvi-open{

  position:fixed;
  top:17px;
  right:8px;
  background-color:white;
  width:30px;
  height:30px;
  z-index:105;
}

.top_menu_item{
  /*background-color:red;*/
  transition: 0.3s;
}

.top_menu_item:hover{
  background-color: #ffde60;
  cursor:pointer
}

a .top_menu_item{
  color:black;
  text-decoration: none;
}


.slider_inner_event{
  /*border:1px solid red;*/
  width:100%;
  height:80%;

  background-size: cover;
  /*border:1px solid #407d00;*/
  box-sizing:border-box;
}

.e_top, .e_bottom{

  height: 20%;
  background-color: rgb(76, 0, 0);
  text-shadow: 1px 1px 1px black;
  color: #deb35d;
  padding-top: 3px;

}

.event_small{
  box-sizing:border-box;
  display: inline-block;
}

.nav_menu_c a{

  color:rgb(236, 173, 31);
  text-decoration:none;

}




</style>
